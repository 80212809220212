.svg{
  display: block;
  max-width: 100%;
}

.svg-circle-bg {
  fill: none;
}

.svg-circle {
  fill: none;
}


