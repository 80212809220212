
.modal-enter {
  opacity: 0;

  .content-wrap {
    transform: translateY(-30px);
  }
}
.modal-enter-active {
  opacity: 1;
  transition: 0.3s;

  .content-wrap {
    transition: 0.3s;
    transform: translateY(0);
  }
}
.modal-exit {
  opacity: 1;
  
  .content-wrap {
    transform: translateY(0);
  }
}
.modal-exit-active {
  opacity: 0;
  transition: 0.3s;
  
  .content-wrap {
    transition: 0.3s;
    transform: translateY(-30px);
    }
}

