
.route-enter {
  opacity: 0;
  transform: translateX(50px);
}
.route-enter-active {
  opacity: 1;
  transition: 0.3s;
  transform: translateX(0);
}
.route-exit {
  opacity: 1;
  transform: translateX(0);
}
.route-exit-active {
  opacity: 0;
  transition: 0.3s;
  transform: translateX(50px);
}