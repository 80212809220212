body {
  font-family: proxima-nova, sans-serif;
  font-size: 100%;
  font-style: normal;
  font-weight: normal;
  background-color: #000;
}

html, body, #root {
  height: 100%;
}

.router-link {
  text-decoration: none;
}